* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

#root {
  min-height: 100vh;
}

ul,
li {
  list-style: none;
}

a {
  color: #191b1f;
  text-decoration: none;
}

a.link {
 color: #A4366D;
}

a.link:hover {
  color: #5a1037;
}