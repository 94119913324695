/* ProfileDetails.css */

.profile-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

  }
 
  .profile-header {
    display: flex;
    justify-content: space-between;
    
  }
  
  .profile-avatar {
    width: 90px;
    height: 90px;
    background-color: #666666;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .profile-avatar-text {
    font-size: 25px;
    color: #ffffff;
  }
  
  .profile-name {
    font-size: 25px;
  }
  
  .change-password-button {
    margin-top: 20px;
  }
  
  .profile-info-container {
    display: flex;
    flex-direction: column;
    height: 210px;
  }

  .profile-border {
    margin-top: 20px;
    border-bottom: 1px solid #e0e0e0;
    width: 100%;
  }

  .iconStyle{
      color: '#A4366D';
      align-self: center;
  }

/* New version of profile details */
.profile-info-container2 {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  height: 210px;
}
  .styledDiv {
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column
  }

  label{
    color: #797F87;
  }

  .placeholder {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    width: 250px; /* Removed quotes */
}


.no-border{
  border: none;
  outline: none;
  width: 100%;
  font-size: 16px;

}

.divContet{
  border-bottom: 1px solid #666666;
  padding-bottom: 5px;
}

