.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .popup {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    width: 300px;
    text-align: center;
  }
  
  .popup h2 {
    margin-bottom: 10px;
  }
  
  .popup input {
    width: 100%;
    margin-bottom: 10px;
    padding: 5px;
    border-radius: 10px;
  }

  .popup .button-group {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .popup .button-group button {
    margin-left: 10px;
  }
.success {
    color: green;
    margin-top: 5px;
    font-size: 14px;
  }

  .error {
    color: red;
    margin-top: 5px;
    font-size: 14px;
  }