/* Custom styles for the calendar */
.react-calendar {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.react-calendar__navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f0f0f0;
    padding: 0.5em;
    border-bottom: 1px solid #ddd;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin-bottom: 0px;
}

.react-calendar__navigation button {
    background: none;
    border: none;
    color: #333;
    font-size: 1em;
    margin: 0 0.5em;
    cursor: pointer;
}

.react-calendar__navigation button:disabled {
    color: #ccc;
}

.react-calendar__month-view__weekdays {
    display: flex;
    justify-content: space-around;
    padding: 0.5em 0;
    background-color: #f0f0f0;
    font-weight: bold;
}

.react-calendar__month-view__days {
    display: flex;
    flex-wrap: wrap;
}

.react-calendar__tile {
    max-width: 100%;
    text-align: center;
    padding: 1em 0.5em;
    background: none;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin: 0.2em;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.react-calendar__tile:enabled:hover {
    background-color: #e6e6e6;
}

.react-calendar__tile--now {
    background: #ffecb3;
    border: 1px solid #f0c14b;
}

.react-calendar__tile--active {
    background: #4CAF50;
    color: white;
    border: 1px solid #4CAF50;
}

.react-calendar__tile--active:enabled:hover {
    background: #45a049;
}

.react-calendar__tile--hasActive {
    background: #4CAF50;
    color: white;
    border: 1px solid #4CAF50;
}

.react-calendar__tile--hasActive:enabled:hover {
    background: #45a049;
}

.react-calendar__month-view__days__day--weekend {
    color: #d10000;
}

.react-calendar__tile--range {
    background: #4CAF50;
    color: white;
    border-radius: 5px;
}

.react-calendar__tile--rangeStart {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.react-calendar__tile--rangeEnd {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
